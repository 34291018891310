// extracted by mini-css-extract-plugin
export var awardCard = "cZ_wJ";
export var awardCard__bdg_large = "cZ_wN";
export var awardCard__bdg_small = "cZ_wP";
export var awardCard__container = "cZ_wV";
export var awardCard__description = "cZ_wT";
export var awardCard__info = "cZ_wQ";
export var awardCard__large = "cZ_wL";
export var awardCard__link = "cZ_wS";
export var awardCard__post = "cZ_wK";
export var awardCard__small = "cZ_wM";
export var awardCard__title = "cZ_wR";